import axios from 'axios';
import getApiUrlByHostname from '../utils/getApiUrlByHostname';

const Axios = axios.create({
  baseURL: getApiUrlByHostname(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default Axios;
